import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { ControlAttribute } from '../../attributes/control.attribute'
import { titles } from '../../data/jobtitles-data'

@Component({
  selector: 'cf-job-title',
  templateUrl: './job-title.component.html',
  styleUrls: ['./job-title.component.scss']
})
export class JobTitleComponent extends ControlAttribute implements OnInit, OnDestroy {
  private _destroy$ = new Subject()
  prediction = ''
  searchTerm: string = ''

  ngOnDestroy(): void {
    this._destroy$.next()
    this._destroy$.complete()
  }

  constructor() {
    super()
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((currentValue: string) => {
      if (!currentValue) return
      const capitalizedFirstLetterRegex = /\b[a-z](?!\s)/g
      const nonLettersRegex = /[^a-zA-Z\s]/g
      const moreThanOneSpaceRegex = / {2,}/g
      currentValue = currentValue
        .replace(nonLettersRegex, '')
        .replace(moreThanOneSpaceRegex, ' ')
        .replace(capitalizedFirstLetterRegex, (letter) => letter.toUpperCase())
      currentValue = currentValue.startsWith(' ') ? currentValue.trim() : currentValue
      this.control.setValue(currentValue, { emitEvent: false })
    })
  }

  @HostListener('keydown.enter', ['$event'])
  onEnterKey(event: KeyboardEvent): void {
    if (this.searchTerm) event.stopImmediatePropagation()
  }

  /**
   * Autofill job title
   * @param currentValue string
   * @returns
   */
  public predictInput() {
    const currentValue = this.control.value
    this.prediction = ''
    const lastWord = currentValue.split(' ').pop()
    this.searchTerm = currentValue.split(' ').pop()
    if (lastWord.length < 2 || lastWord === 'of' || lastWord === 'the') return
    let prediction = ''

    prediction = titles.find((title) => title.startsWith(lastWord))
    if (prediction) this.prediction = prediction
  }

  /**
   * Apply prediction to input
   * @param event KeyboardEvent
   * @returns
   */
  applyPrediction(event: KeyboardEvent) {
    if (this.prediction) {
      const lastWord = this.control.value.split(' ').pop()
      const newValue = this.control.value.replace(lastWord, this.prediction)
      this.control.setValue(newValue + ' ')
      this.prediction = ''
      event.preventDefault()
    }
  }

  /**
   * Calculate left position of the prediction to align with the input
   * @returns number
   */
  calculateLeftPosition() {
    const inputValue = this.control.value || ''
    const words = inputValue.split(' ').length
    const lastWord = inputValue.split(' ').pop() || ''
    const isFirstWord = inputValue === lastWord
    if (isFirstWord) return 0

    // Create a temporary span to measure text width
    const span = document.createElement('span')
    span.style.visibility = 'hidden'
    span.style.whiteSpace = 'nowrap'
    span.style.fontFamily = 'Roboto, sans-serif'
    span.style.fontSize = '14px'
    span.style.fontWeight = 'normal'
    span.innerHTML =
      inputValue
        .split(' ')
        .slice(0, words - 1)
        .join(' ') + '&nbsp;'
    document.body.appendChild(span)
    const leftPosition = span.getBoundingClientRect().width
    document.body.removeChild(span)
    return leftPosition
  }
}
